import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Container } from '../../layout/Container';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { createLeadMutation } from '../../../../client/__graphql__/mutations';
import { useMutation } from '@apollo/client';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { useForm } from '../../../utils/hooks/useForm';
import { Form, FormRow, FormWrapper } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { Textarea } from '../../ui/form/Textarea';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { routeConfig } from '../../../__config__/routes';

const Nordstrandsdagene: React.FC = () => {
  const [posted, setPosted] = React.useState(false);

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
              success
            }`
    }
  });

  const [mail, { loading, error }] = useMutation<ILeadResponse, ILeadInput>(
    MAIL_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.mail?.success) {
          setPosted(true);
        }
      }
    }
  );

  return (
    <NordsstrandsDagene>
      <Container>
        <Heading tag="h1" center={true}>
          Norstrandsdagene 5-11 Juni 2023
        </Heading>

        <EmbedContainer>
          <iframe
            className="VimeoOuterContainer"
            /*             src="https://player.vimeo.com/video/832527522?amp;loop=0&background=1&autoplay=1&muted=1&controls=1" */
            src="https://player.vimeo.com/video/832527522?amp;loop=0&background=0"
            frameBorder="0"
            width="100%"
            height="100%"
          ></iframe>
        </EmbedContainer>

        <Paragraph center={true}>
          Fra 5. juni til 11. juni vil det være fullt av aktiviteter og
          arrangementer for store og små i bydelen.{' '}
          <a
            href="https://storymaps.arcgis.com/stories/a8e94575d69a4beb88021633e373f827?fbclid=IwAR0zoINmZ46zdgli1d-KEFWq4cf6KHWxWDHyxUaFFbXGyWhoZR470pB1i_8_aem_th_AYA6FjXvH5lNaIQBxwCS46WDvxsUdehoHVilNrHNtZaxxhGheTZCjAdi7kgYvfkUh5g"
            target="__blank"
          >
            Sjekk
          </a>{' '}
          ut programmet og bli med på fest og moro sammen med frivilligheten og
          Bydel Nordstrand
        </Paragraph>
      </Container>
    </NordsstrandsDagene>
  );
};

const NordsstrandsDagene = styled.div`
  padding-top: 7em;
`;

const EmbedContainer = styled.div`
  position: relative;
  /*   padding: 38.07% 0 0 0; */
  padding: 53.07% 0 0 0;
  margin-top: 4em;
  .VimeoOuterContainer {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media all and (max-width: 375px) {
    padding: 73.07% 0 0 0;
    margin-top: 0px;
  }
`;

export default Nordstrandsdagene;
